import { Vue, Component } from 'vue-property-decorator';
import { Token } from '@/support/Token';
import { identifyHostname } from '@/support/Client';
import axios from 'axios';

@Component
export default class AAxiosClientMixin extends Vue {
  public oAuthServer: string = identifyHostname() || '';

  protected getAxiosClient(headers: {[key: string]: string} = {}) {
    if (Token.get('access')) {
      (headers as any).Authorization = `Bearer ${Token.get('access')}`;
    }
    const axiosClient = axios.create({
      baseURL: this.oAuthServer,
      headers,
    });
    return axiosClient;
  }
}
