var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ACard",
    { staticClass: "tw-mt-8", attrs: { title: "VES import tool" } },
    [
      _c("div", { staticClass: "tw-flex tw-flex-wrap" }, [
        _c(
          "div",
          {
            staticClass:
              "fileUpload__container tw-flex tw-flex-wrap tw-w-full tw-h-full"
          },
          [
            _c("MiFileUpload", {
              ref: "fileUpload",
              attrs: {
                clearAfterUpload: true,
                multiple: false,
                acceptedFileTypes: _vm.acceptedFileTypes
              },
              on: {
                itemDropped: function($event) {
                  return _vm.handleItemDropped($event)
                }
              }
            })
          ],
          1
        ),
        _vm.importResponse &&
        _vm.importResponse.logs &&
        _vm.importResponse.logs.length
          ? _c(
              "div",
              { staticClass: "tw-flex tw-flex-wrap tw-w-full tw-mt-16" },
              [
                _c("div", { staticClass: "tw-flex tw-flex-col tw-w-full" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "tw-text-success-500 tw-font-bold tw-flex tw-items-center"
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "tw-text-success-500 tw-mr-8" },
                        [_vm._v("check_circle")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.importResponse.success) +
                          "/" +
                          _vm._s(_vm.importResponse.row_count) +
                          " succesvolle imports "
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "tw-text-error-500 tw-font-bold tw-flex tw-items-center tw-mt-8"
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "tw-text-error-500 tw-mr-8" },
                        [_vm._v("error")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.importResponse.failed) +
                          "/" +
                          _vm._s(_vm.importResponse.row_count) +
                          " mislukte imports "
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-overflow-auto tw-max-h-400 tw-mt-16"
                  },
                  [
                    _c("List", {
                      staticClass: "tw-w-full tw-mb-16 tw-p-8",
                      attrs: {
                        labels: _vm.importColumns,
                        items: _vm.importResponse.logs
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "row",
                            fn: function(item) {
                              return [_vm._v(" " + _vm._s(item.row) + " ")]
                            }
                          },
                          {
                            key: "report",
                            fn: function(item) {
                              return [_vm._v(" " + _vm._s(item.report) + " ")]
                            }
                          },
                          {
                            key: "message",
                            fn: function(item) {
                              return [_vm._v(" " + _vm._s(item.message) + " ")]
                            }
                          }
                        ],
                        null,
                        false,
                        505765970
                      )
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }