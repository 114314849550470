import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import { mixins } from 'vue-class-component';
import AAxiosClientMixin from '@/mixins/AAxiosClientMixin';
import { Label } from '@/components/List/List';

@Component<VESImportTool>({})
export default class VESImportTool extends mixins(Vue, AAxiosClientMixin) {
  // File structure for Typescript files in your Vue project
  // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Props
  // #endregion

  // #region @Refs
  @Ref()
  protected fileUpload!: MiFileUpload;
  // #endregion

  // #region Class properties
  public $pageTitle = 'VES import tool';

  protected acceptedFileTypes: string[] = [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'text/csv',
  ];

  protected importResponse: VESImportResponse | null = null;
  // #endregion

  // #region Lifecycle Hooks / Init
  protected mounted(): void {
    this.emitBreadcrumb();
  }
  // #endregion

  // #region Class methods
  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'VES import tool' },
        ],
      });
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.postFile(file);
  }
  // #endregion

  // #region Async methods
  protected async postFile(file: File | string): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);
    const client = this.getAxiosClient({
      'Content-Type': 'multipart/form-data',
    });

    try {
      const response = await client
        .post('v1/tools/ves/update', formData);

      console.log('response', response.data);
      this.importResponse = response.data;
      this.fileUpload.uploaded();
    } catch (error) {
      ErrorHandler.network(error);
      this.fileUpload.uploaded();
    }
  }
  // #endregion

  // #region Getters & Setters
  protected get importColumns(): Label[] {
    return [
      {
        title: 'Regel',
        slotName: 'row',
        width: 'xs1',
      },
      {
        title: 'Report',
        slotName: 'report',
        width: 'xs3',
      },
      {
        title: 'Bericht',
        slotName: 'message',
        width: 'xs8',
      },
    ];
  }
  // #endregion

  // #region @Watchers
  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
  // #endregion
}

interface VESImportResponse {
  failed: number;
  logs: string[];
  row_count: number;
  success: string;
}
